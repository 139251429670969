<template>
  <div class="fixed_margin">
    <h5>Create a New Addition</h5>

    <validation-observer
      ref="spec_item_form"
      tag="form"
    >
      <!--      <validation-provider-->
      <!--        #default="{ errors }"-->
      <!--        name="Specification Name"-->
      <!--        rules="required"-->
      <!--      >-->

      <!--        <label for="name">Specification name:</label>-->
      <!--        <b-form-input v-model="form.name" />-->
      <!--        <small class="text-danger">{{ errors[0] }}</small>-->
      <!--      </validation-provider>-->
      <label class="fixed_margin_top" for="name">Item Name</label>
      <b-form-input placeholder="Ex. Access Control" />
      <label class="fixed_margin_top" for="name">Descripttion for clients <small class="text-muted"> optional</small></label> <br>
      <b-form-textarea placeholder="Ex. HID-compatible system, 2x entry doors" />
      <small class="text-muted">Shown to clients if the item is not hidden in turnkey.</small>
      <br>
      <label class="fixed_margin_top" for="internal note">Internal Note <small class="text-muted">optional</small></label>
      <b-form-textarea placeholder="Internal notes and reasos for changes." />
      <small class="text-muted">This field is required when making an adjustment to base spec.</small>
      <br>
      <label class="fixed_margin_top" for="select">Select Budget Group</label>
      <v-select
        v-model="form.budget_group"
        :clearable="false"
        :options="budget_group"
        label="name"
        :reduce="(option) => option.key"
        @input="clearSelects"
      />

      <div class="fixed_margin_top">
        <validation-provider
          #default="{ errors }"
          name="Element to attach"
          rules="required"
        >
          <label for="select">Tender code</label>
          <v-select
            v-model="selected_item"
            :clearable="false"
            :options="
              form.budget_group === 'CNST'
                ? cnst
                : form.budget_group === 'FF&E'
                  ? ffe
                  : avit
            "
            :label="
              form.budget_group === 'CNST' ? 'construction_spec' : 'element_name'
            "
          />
          <small class="text-danger">{{ errors[0] }}</small>
          <div class="row fixed_margin_top">
            <div class="col-6">
              <label for="price">Total Price (w/ D&I)</label>
              <b-form-group>
                <b-input-group prepend="$">
                  <b-form-input
                      v-model="cost"
                      type="number"
                      placeholder="$0.00"
                      @keyup="constructionPrice()"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </div>
            <div class="col-6">
              <label for="price">Total COGS</label>
              <b-form-group>
                <b-input-group prepend="$">
                  <b-form-input
                      v-model="cost"
                      type="number"
                      placeholder="$0.00"
                      @keyup="constructionPrice()"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </div>
            <div class="col-12">
              <b-form-checkbox
                  v-if="budgetGroup == 'AV/IT'"
                  id="checkbox-1"
                  v-model="isBaseSpec"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                  disabled
              >
                AV/IT items cannot be rolled up in Base Contract
              </b-form-checkbox>
              <b-form-checkbox
                  v-else-if="budgetGroup == 'Soft Cost'"
                  id="checkbox-1"
                  v-model="isBaseSpec"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                  disabled
              >
                Soft Cost items cannot be rolled up in Base Contract.
              </b-form-checkbox>
              <b-form-checkbox
                  v-else
                  id="checkbox-1"
                  v-model="isBaseSpec"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="isAdjustment"
              >
                {{
                  isAdjustment
                      ? "All Adjustments are Hidden in Turnkey"
                      : "Roll Up in Base Contract"
                }}
              </b-form-checkbox>
            </div>
          </div>
        </validation-provider>
      </div>
     </validation-observer>
    <div class="text-right">
      <b-button
        variant="primary"
        class="my-2"
        @click="addSpecificationItem"
      >Save Addition
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BButton, BCol, BFormCheckbox, BFormInput, BFormRadio, BFormTextarea, BInputGroup, BOverlay, BRow, BTable, BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { UilGripHorizontalLine, UilTimes } from '@iconscout/vue-unicons'
import { required } from '@core/utils/validations/validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    BFormCheckbox,
    BOverlay,
    BFormInput,
    BButton,
    BFormTextarea,
    vSelect,
    BCol,
    BRow,
    UilTimes,
    UilGripHorizontalLine,
    BInputGroup,
    BTable,
    BFormRadio,
    required,
    ValidationProvider,
    ValidationObserver,
    BFormGroup
  },
  props: {
    specItem: {
      type: Object,
    },
    base_items: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      step: 1,
      budget_group: [
        { name: 'Construction', key: 'CNST' },
        { name: 'FF&E', key: 'FF&E' },
        { name: 'AV/IT', key: 'AVIT' },
      ],
      specification_type: 'reuse',
      form: {
        budget_group: this.specItem.budget_group,
        cogs_per_unit: this.specItem.cogs_per_unit,
        construction_work_id: this.specItem.construction_work_id,
        id: this.specItem.id,
        manufacturer: this.specItem.manufacturer,
        markup: this.specItem.markup,
        name: this.specItem.name,
        price_per_unit: this.specItem.price_per_unit,
        specification_swap_set_id: this.specItem.specification_swap_set_id,
        uid: this.specItem.uid,
        unit_delivery_install: this.specItem.unit_delivery_install,
      },
      emptyForm: {
        budget_group: 'CNST',
        cogs_per_unit: 0,
        construction_work_id: null,
        id: 1,
        manufacturer: null,
        markup: 10,
        name: null,
        price_per_unit: 0,
        specification_swap_set_id: 5,
        uid: null,
        unit_delivery_install: 22.5,
      },
      avit: this.base_items.avit,
      cnst: this.base_items.cnst,
      ffe: this.base_items.ffe,
      selected_item: this.specItem.budget_group === 'CNST' ? this.specItem.construction_work : this.specItem.element,
    }
  },
  computed: {
    total_price() {
      return Number(
        Number(this.form.cogs_per_unit) * (1 + (Number(this.form.markup) / 100)),
      )
    },
    total_price_w_delivery() {
      if (this.form.budget_group != 'CNST') {
        return Number(this.total_price * (1 + (this.form.unit_delivery_install / 100)))
      }
      return this.form.price_per_unit
    },
  },
  methods: {
    clearSelects() {
      this.selected_item = null
      this.form.cogs_per_unit = 0
      this.form.construction_work_id = null
      this.form.id = null
      this.form.manufacturer = null
      this.form.markup = 10
      this.form.price_per_unit = 0
      this.form.specification_swap_set_id = 5
      this.form.uid = null
      this.form.unit_delivery_install = 22.5
    },
    addSpecificationItem() {
      return new Promise((resolve, reject) => {
        this.$refs.spec_item_form.validate().then(success => {
          if (success) {
            if (this.form.budget_group !== 'CNST') {
              this.form.price_per_unit = this.total_price_w_delivery
              this.form.uid = this.selected_item.uid
              this.form.construction_work_id = null
            } else {
              this.form.construction_work_id = this.selected_item.id
              this.form.uid = null
              this.form.unit_delivery_install = 0
              this.form.markup = 0
            }
            this.$emit('saveItem', this.form)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style scoped>
.fixed_margin{
  margin-right: 15px;
  margin-top: 15px;
  margin-left: 15px;
}
.fixed_margin_top{
  margin-top: 15px;
}
</style>
